
.footnotes {
    margin-top: 2em;
    &::before {
        border-top: 1px solid black;
        width: 60%;
        padding-top: .5em;
        margin-left: 1em;
        display: inline-block;
        content: "Footnotes:";
        font-weight: bold;
    }
    p {
        margin: 0;
    }
}


.github_logo {
    background-image: url("/assets/images/github.png");
    width: 1.5em;
    height: 1em;
    background-position: left;
    background-size: contain;
    background-repeat: no-repeat;
    display: inline-block;
}
