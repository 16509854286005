
body {

    background-color: #f2f2f2;

    main {
        display:flex;
        flex-direction:row;
        justify-content:center;
        align-items:center;
        margin: auto;

        a {
            width: 50%;
        }

        figcaption {
            height: 3em;
        }
    }

}