
body {
    width: 100vw;
    margin: auto;
    font-family: Verdana, Geneva, Tahoma, sans-serif;

    @media only screen and (min-width: 1000px) {
        max-width:1000px;
        box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
    }

    background-color: #f2f2f2;

    >* {
        background-color: #ffffff;
        padding-left: 16px;
        padding-right: 16px;
    }

    a {
        color: black;
    }
    main {
        padding-top: 1cm;
        @media only screen and (min-width: 1000px) {
            padding: 1cm 2cm 1cm 2cm;
        }

        p, li {
            h2 {
                text-align: left;
            }
        }

        .svg_subpage {
            overflow-x: auto;
            svg {
                display: block;
                margin-left: auto;
                margin-right: auto;
            }
        }

        @media only screen and (max-width: 1000px) {
            sup {
                vertical-align: bottom;
                font-size: medium;
                &::before {
                    content: "[";
                }
                &::after {
                    content: "]";
                }
            }
            .mjpage__block {
                overflow-x: auto;
            }
        }

        h1.top_header {
            margin-bottom: 1cm;
            text-align: center;
            @media only screen and (min-width: 1000px) {
                font-size: 2.5em;
            }
        }


        table {
            margin: auto;
            tr {
            }
            thead > tr {
                background-color: lightgrey;
            }
            tbody > tr:nth-child(even) {
                background-color: #f0f0f0;
            }
        }

        ul {
            // padding-inline-start: 0em;
            // padding-inline-end: 0em;
            // @media only screen and (min-width: 1000px) {
            //     padding-inline-start: 2em;
            //     padding-inline-end: 2em;
            // }
        }

        ul.post_listing {
            list-style: none;
            padding-inline-start: 0em;
            padding-inline-end: 0em;
            li + li {
                &:before {
                    content: '';
                    display: block;
                    width: 60%;
                    margin: auto;
                    height: 1px;
                    border-top: black solid 1px;
                    margin-top: 1cm;
                    margin-bottom: 1cm;
                }
            }
        }

        ul.pagination_trail {
            list-style: none;
            display: flex;
            justify-content: center;
            padding-inline-start: 0;

            li {
                display: inline;
                a {
                    display: block;
                    color: black;
                    float: left;
                    padding: 8px 16px;
                    text-decoration: none;

                    &.selected {
                        background-color: #f2f2f2;
                    }
                    &:hover {
                        background-color: lightslategrey;
                    }
                }
            }
        }
    
        img {
            max-width:100%;
        }
    
        figure {
            text-align:center;
            margin-block-start: 0;
            margin-block-end: 0;
            margin-inline-start: 0;
            margin-inline-end: 0;
        }
    }

    img {
        max-width: 100%;
    }

    footer {
        padding-bottom: 2em;
    }
}