
header {
    img {
        width:100%;
    }
    nav {
        max-width: 90%;
        margin: auto;
        display: flex;
        justify-content: flex-end;
        
        ul {
            list-style-type: none;
            li {
                display: inline-block;

                a {
                    text-decoration: none;
                    display: block;
                    padding: 8px 16px;
                }
        
                a:hover {
                    background-color: lightslategrey;
                }

                ul {
                    padding-inline-start: 0;
                    li {
                        display: block;
                    }
                }
                .submenu {
                    display: none;
                    position: absolute;
                    background-color: #f9f9f9;
                    box-shadow: 0px 8px 16px 0px rgba(0,0,0,.2);
                    z-index: 1;
                }

                &:hover .submenu {
                    display: block;
                }
            }
        }
    }

    &:after {
        content: "";
        display: block;
        width: 80%;
        height: 1px;
        margin: auto;
        border-bottom: black solid 1px;
    }
}